import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Otdelka = ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <SEO title="Отделочные работы" />

    <section id="otdelka" className="pb80 pt100 bg-dark uslugi otdelka">

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="section-title mb25">
              <h1>Отделочные работы</h1>
              <p>Построить жилое или коммерческое здание – это лишь часть дела. Один из самых ответственных этапов строительства – отделка, которая не терпит ошибок и применения низкокачественных материалов. Именно поэтому важно обращаться к настоящим профессионалам. Специалисты компания «СК МАСТЕР» выполняют отделочные работы премиум-качества по доступным ценам.</p>
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h2 className="color-style2">
              Что мы предлагаем
              </h2>
            <p>Понятие «отделка» включает в себя целый комплекс работ, включающий:</p>
            <ul className="list-group checklist tworows">
              <li>поклейку обоев;</li>
              <li>шпаклевку;</li>
              <li>штукатурные работы;</li>
              <li>грунтовку;</li>
              <li>укладку плитки;</li>
              <li>стяжку пола;</li>
              <li>монтаж конструкций из гипсокартона;</li>
              <li>установку многоуровневых и натяжных потолков;</li>
              <li>декоративную шпаклевку;</li>
              <li>покраску потолков, стен и полов;</li>
              <li>установку плинтусов и т.д.</li>
            </ul>
            <p>
              Добиться хорошего результата можно, если использовать качественные материалы и соблюдать технологию.
            </p>
          </div>

        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h2 className="color-style2">
              «Плюсы» отделки от «СК МАСТЕР»
              </h2>
            <p>Физические и юридические лица, уже успевшие заказать отделочные работы в компании «СК МАСТЕР», выделяют следующие наши преимущества:</p>
            <ul className="list-group checklist">
              <li>возможность сэкономить деньги. Мы работаем с хорошо зарекомендовавшими себя производителями и поставщиками отделочных материалов, используем современные технологии и оборудование, имеем большой опыт, поэтому предлагает первоклассную отделку по доступным ценам;</li>
              <li>соблюдение сроков. Мы понимаем, что время – это самый ценный и невосполнимый ресурс, поэтому никогда не заставляем наших клиентов ждать дольше, чем указано в договоре;</li>
              <li>надежность. Мы официальная компания, у которой есть все необходимые допуски и сертификаты.</li>
            </ul>
            <p>
              Пользуясь нашими услугами, Вы выбираете надежного и проверенного исполнителя профессионального уровня! В «СК МАСТЕР» работают истинные профи, которые ответственно относятся к решению каждой задачи. Оцените «плюсы» работы с нами и Вы. Свяжитесь с нашими менеджерами любым удобным способом.
            </p>
          </div>
        </div>
      </div>
    </section>

  </Layout >
)

export default Otdelka
